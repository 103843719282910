var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "app-container" } },
    [
      _c("top-nav-bar"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-flex h-100 w-100 body" },
        [
          _vm.$auth.loggedIn ? _c("left-bar") : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "flex-grow-1 right-content",
              class: { "login-page": _vm.$route.path.includes("/login") },
            },
            [
              _c("nuxt", {
                staticClass: "nuxt-content",
                class: { content: _vm.$auth.loggedIn },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.isLoading ? _c("LoadingLayout") : _vm._e(),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-no-internet",
            "ok-only": "",
            okVariant: "danger",
            title: _vm.$t("error.error"),
            headerClass: "px-4 pt-3 pb-0 border-bottom-0",
            bodyClass: "px-4 py-2",
            footerClass: "px-4 pb-3 pt-0 border-top-0",
            "button-size": "sm",
            "ok-title": _vm.$t("common.ok"),
            "cancel-title": _vm.$t("account_info.cancel"),
          },
          on: {
            ok: () => {
              _vm.isOffline = false
            },
          },
          model: {
            value: _vm.isOffline,
            callback: function ($$v) {
              _vm.isOffline = $$v
            },
            expression: "isOffline",
          },
        },
        [
          _c("p", { staticClass: "my-4" }, [
            _vm._v(_vm._s(_vm.$t("error.no_internet"))),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }