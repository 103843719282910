// Importing dictionaries for vee-validate
import ja from 'vee-validate/dist/locale/ja'
import en from 'vee-validate/dist/locale/en'
import vi from 'vee-validate/dist/locale/vi'
import { Validator, localize } from 'vee-validate'

// localize('en', en);
// localize('vi', vi);
// localize('ja', ja);

// export default function ({ app }) {
//   //set locale for moment
//   // app.$moment.locale(app.i18n.locale);
//   // Loading languages for Vee
//   Validator.localize('ja', {
//     messages: ja.messages
//   })
//   Validator.localize('vi', {
//     messages: vi.messages
//   })

// }

// import {configure } from "vee-validate";

// export default function ({ app }) {
//     configure({
//         defaultMessage: (field, values) => {
//             values._field_ = app.i18n.t(`${field}`);
//             return app.i18n.t(`validation.${values._rule_}`, values);
//         }
//     });
// }