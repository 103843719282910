export const userRoles = {
  ROLE_MOD: 'ROLE_MOD',
  ROLE_USER: 'ROLE_USER',
  ROLE_ADMIN: 'ROLE_ADMIN'
}

export const userTypeWorkings = {
  ALL: 'ALL',
  FULL_TIME: 'FULL_TIME',
  PART_TIME: 'PART_TIME'
}

export const userGenders = {
  MALE: 0,
  FEMALE: 1
}

export const userStatus = {
  WORK: 'WORK',
  STOP_WORKING: 'STOP_WORKING'
}