
<template>
  <header class="position-fixed z-index-10 w-100" :class="{'bg-white': $auth.loggedIn}">
    <div class="d-flex justify-content-between navbar-wrapper">
      <nuxt-link class="logo" :class="{'logo-login': $route.path == localePath('/login')}" :to="getUrlHomePage" @click.native.stop="onScrollTop()">
         <!-- <img src="~/assets/images/logo.png" alt="" srcset="" style=" "> -->
         <img src="~/assets/images/logo-vj@2x.png" alt="" srcset="" style=" ">
      </nuxt-link>
      <div class="d-flex justify-content-between align-items-center">
        <div class="language mr-3">
          <v-select 
            v-model="language"
            :options="listLanguages" 
            @change-data="onChangeLanguage"
            :isRight="true"
            itemsWidth="240px"
          />
        </div>
        <div class="icon-menu"
          v-show="!$route.path.includes('/login')"
          @click="isOpen = !isOpen"
          v-click-outside="() => { isOpen = false }"
        >
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="19.9609" y="6.44385" width="2.89474" height="16" transform="rotate(89.5967 19.9609 6.44385)" fill="#183052"/>
            <rect x="19.9937" y="11.0752" width="2.31579" height="16" transform="rotate(89.5967 19.9937 11.0752)" fill="#183052"/>
            <rect x="20.0264" y="15.7068" width="1.73684" height="16" transform="rotate(89.5967 20.0264 15.7068)" fill="#183052"/>
          </svg>
          <div class="dropdown_type" v-if="isOpen">
            <div class="mt-1"></div>
            <div v-if="$auth.loggedIn">
              <nuxt-link :to="localePath('/account/terminate')" v-if="$auth.user.role=='ROLE_ADMIN' || $auth.user.role=='ROLE_MOD'" class="d-flex dropdown_type__item">{{ $t('auth.terminate') }}</nuxt-link>

              <nuxt-link :to="localePath('/change-password')" v-if="$auth.user.role!=='ROLE_ADMIN'" class="d-flex dropdown_type__item">{{ $t('auth.change_password') }}</nuxt-link>
              <div class="dropdown_type__item" @click="confirmLogout()">
                <span>{{ $t('auth.log_out') }}</span>
              </div> 
            </div>
            <div v-else>
              <nuxt-link :to="localePath('/login')" class="d-flex dropdown_type__item">{{ $t('auth.log_in') }}</nuxt-link>
            </div>
            <div class="mb-1"></div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import VSelect from '~/components/forms/VSelect.vue';
import { localize } from 'vee-validate';

export default {
  name: 'TopNavBar',
  components: {
    VSelect
  },
  data() {
    return {
      language: this.$i18n.locale,
      listLanguages: [
        { id: 'en', name: this.$t('auth.english', 'en')},
        { id: 'ja', name: this.$t('auth.japanese', 'ja')},
        { id: 'vi', name: this.$t('auth.vietnamese', 'vi')},
      ],
      isOpen: false
    }
  },
  computed: {
    getUrlHomePage() {
      if (this.$auth.loggedIn && this.$auth.user.role === 'ROLE_USER') {
        return this.localePath('/working-time');
      }
      return this.localePath('/account')
    }
  },
  methods: {
    onLogout() {
      this.$auth.logout().then(() => {
        this.$router.push(this.localePath('/login'))
      });
    },
    confirmLogout() {
      this.$bvModal.msgBoxConfirm(this.$t('auth.confirm_logout'), {
        title: this.$t('auth.log_out'),
        size: 'md',
        buttonSize: 'sm',
        okVariant: 'primary',
        headerClass: 'p-3 border-bottom-0',
        footerClass: 'p-3 border-top-0',
          okTitle: this.$t('common.ok'),
          cancelTitle: this.$t('account_info.cancel'),
        hideHeaderClose: false,
        // centered: true
      }).then(async (value) => {
        if(value) {
          try {
            this.$auth.logout().then(() => {
              this.$router.push(this.localePath('/login'))
            });
          } catch (error) {
            this.$handleErrorApi(error, error => {
              this.$bvModal.msgBoxOk(this.$t(`error.${error}`).toString(), {
                title: this.$t('error.error').toString(),
                size: 'md',
                buttonSize: 'sm',
                okVariant: 'danger',
                headerClass: 'p-3 border-bottom-0',
                footerClass: 'p-3 border-top-0',
                okTitle: this.$t('common.ok'),
                cancelTitle: this.$t('account_info.cancel'),
                hideHeaderClose: false,
              }).then(value => {
              }).catch(err => {
              })
            })
          }
        }
      }).catch(err => {
      })
    },
    onChangeLanguage(lang) {
      // this.$root.$i18n.locale = lang;
      this.$router.go(0) //reload page
      // this.$i18n.setLocale(lang);
      this.$i18n.setLocaleCookie(lang);
      // localize(lang);
      this.$router.push(this.switchLocalePath(lang));
    },
    onScrollTop() {
      this.$nextTick(() => {
        window.scrollTo(0,0);
      })
    }
  }
}
</script>

<style scoped lang="scss">
header {
  z-index: 10;
}
// .padding-40 {
//   padding: 40px;
// }
.navbar-wrapper {
  padding: 22px 38px;
  .logo {
    img {
      height: 32px;
    }
    &.logo-login {
      cursor: default;
      pointer-events: none;
      // img {
      //   height: 77px;
      // }
    }
  }
}
// .language {
  // width: 126px;
// }

.icon-menu {
  position: relative;
  cursor: pointer;
  .dropdown_type {
    position: absolute;
    border-top: 0;
    box-sizing: border-box;
    top: calc(100% + 8px);
    right: 0;
    width: 240px;
    box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
    filter: none;
    &__item {
      background: #FFF;
      padding: 5px 12px;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.85);
      text-decoration: none;
      &:hover {
        background: #4DA4D6;
        font-weight: 500;
      }
    }
  }
}
</style>