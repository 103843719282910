import Vue from "vue";

Vue.mixin({
  data: () => ({
  }),
  methods: {
    formatDate(timestamp) {
      const date = new Date(timestamp);
      return (
        date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear()
      );
    },
    uniqueArray(unprocessedArray) {
      var seen = {};
      var processedArray = [];
      var length = unprocessedArray.length;
      var j = 0;
      for (var i = 0; i < length; i++) {
        var item = unprocessedArray[i];
        if (seen[item] !== 1) {
          seen[item] = 1;
          processedArray[j++] = item;
        }
      }
      return processedArray;
    },
    setCookie(cname, cvalue, exdays) {
      const d = new Date();
      d.setTime(d.getTime() + (exdays*24*60*60*1000));
      const expires = "expires="+d.toUTCString();
      document.cookie = cname + "=" + cvalue + "; " + expires;
    },
    setCookieByMinute(cname, cvalue, expireTimeByMinute) {
      const d = new Date();
      d.setTime(d.getTime() + (expireTimeByMinute*60*1000));
      const expires = "expires="+d.toUTCString();
      document.cookie = cname + "=" + cvalue + "; " + expires ;
    },

    getCookie(cname) {
        const name = cname + "=";
        const ca = document.cookie.split(';');
        for(let i=0; i<ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0)==' ') c = c.substring(1);
            if (c.indexOf(name) == 0) return c.substring(name.length, c.length);
        }
        return "";
    },
    formatLocaleDate(_dateTime, formatDate='L', locale='') {
      if (locale) {
        return this.$moment(_dateTime).locale(locale).format(formatDate);
      }
      return this.$moment(_dateTime).locale(this.$i18n.locale).format(formatDate);
    },
    dynamicSort(property) {

      let sortOrder = 1;
  
      if(property[0] === "-") {
          sortOrder = -1;
          property = property.substr(1);
      }
  
      return function (a,b) {
          if(sortOrder == -1){
              return b[property].localeCompare(a[property]);
          }else{
              return a[property].localeCompare(b[property]);
          }        
      }
    },
    compareObject(o1, o2) {
      return typeof o1 === 'object' && o1 !== null && Object.keys(o1).length > 0 
        ? Object.keys(o1).length === Object.keys(o2).length 
        && Object.keys(o1).every(p => this.compareObject(o1[p], o2[p]))
        : o1 == o2;
    },
    formatPrice(value) {
      // let val = (value / 1).toFixed(2).replace(" ", ",");
      // return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      return value?.toLocaleString(
        undefined, // leave undefined to use the visitor's browser 
                   // locale or a string like 'en-US' to override it.
        { minimumFractionDigits: 2 }
      );
    },
    getFormatDateByLocale() {
      switch (this.$i18n.locale) {
        case 'ja':
          return 'YYYY/MM/DD';
        case 'en':
          return 'MM/DD/YYYY';
        case 'vi':
          return 'DD/MM/YYYY'
        default:
          return 'MM/DD/YYYY';
      }
    },
    async validateTab(refName) {
      const isValid = await this.$refs[refName].validate();
      if (!isValid) {
        setTimeout(() => {
          const [first] = Object.entries(this.$refs[refName].errors)
                        .map(([key, value]) => ({ key, value }))
                        .filter(error => error["value"].length);
                        console.log('[first]', [first]);
          this.$refs[refName].refs[first["key"]].$el.scrollIntoView({
            behavior: "smooth",
            block: "center"
          });
          this.$refs[refName].refs[first["key"]].$el.focus();
        }, 100);
      }
      return isValid;
    }
  },
  filters: {
    formatPriceFilter(value) {
      return value.toLocaleString(
        undefined, // leave undefined to use the visitor's browser 
                  // locale or a string like 'en-US' to override it.
        { minimumFractionDigits: 2 }
      );
    },
    formatTime(time, dec) {
      return time.toFixed(dec);
    }
  }
});
// Vue.filter('formatPriceFilter', function (value) {
//   return value.toLocaleString(
//     undefined,
//     { minimumFractionDigits: 4 }
//   );
// })