const handleHover = (id, line) =>
{
  try{
    const box = document.getElementById(id);
    if (box) {
      if (line == 1) {
        console.log(box?.offsetWidth < box?.scrollWidth, box?.offsetWidth ,box?.scrollWidth);
        return box?.offsetWidth < box?.scrollWidth;
      }
      const oldWidth = box.offsetWidth;
      let box2 = box.cloneNode(true);
      document.body.appendChild(box2);
      box2.style.display = 'inline';
      box2.style.whiteSpace = 'nowrap';
      box2.style.visibility = 'hidden';
      box2.style.position = 'fixed';
      box2.style.zIndex = '-1';
      console.log(box2?.scrollWidth > oldWidth * line, box2?.scrollWidth ,oldWidth * line);
      const isEllipsis = box2?.scrollWidth > oldWidth * line
      document.body.removeChild(box2);
      return isEllipsis;
    }
    console.log('aaa');
    return false;
  }catch(e){
    console.log(e)
  }
}

export default ({ app }, inject) => {
  // Inject $hello(msg) in Vue, context and store.
  inject('handleHover', handleHover);
}