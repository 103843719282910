var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "13",
        height: "26",
        viewBox: "0 0 13 26",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("g", { attrs: { "clip-path": "url(#clip0_1210_96)" } }, [
        _c("path", {
          attrs: {
            d: "M12.0681 13.2641L3.15191 20.6855C2.86645 20.9212 2.40485 20.9212 2.11938 20.6855L0.916784 19.6927C0.631319 19.457 0.631319 19.0759 0.916784 18.8402L8.11414 12.8378L0.916783 6.83544C0.631318 6.59975 0.631318 6.21865 0.916783 5.98297L2.11938 4.99009C2.40484 4.75441 2.86645 4.75441 3.15191 4.99009L12.0681 12.4116C12.3536 12.6473 12.3536 13.0284 12.0681 13.2641Z",
          },
        }),
      ]),
      _vm._v(" "),
      _c("defs", [
        _c("clipPath", { attrs: { id: "clip0_1210_96" } }, [
          _c("rect", {
            attrs: {
              width: "11.6615",
              height: "25.6744",
              fill: "white",
              transform: "translate(12.323 25.6748) rotate(180)",
            },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }