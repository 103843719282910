<template>
  <component :is="iconName" :fill="fill" :width="width" :height="height" />
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true
    },
    fill: {
      type: String,
      default: 'currentColor'
    },
    width: {
      type: [String, Number],
      default: '24'
    },
    height: {
      type: [String, Number],
      default: '24'
    },
    directory: {
      type: String, 
      default: 'root'
    }
  },
  computed: {
    iconName() {
      if(this.directory === 'root'){
        return require(`~/assets/icons/${this.name}.vue`).default;
      }
      else {
        return require(`~/assets/icons/${this.directory}/${this.name}.vue`).default;
      }

    }
  }
}
</script>