import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _4dfcd712 = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _34244e5f = () => interopDefault(import('../pages/change-password.vue' /* webpackChunkName: "pages/change-password" */))
const _384571cc = () => interopDefault(import('../pages/forgot-password.vue' /* webpackChunkName: "pages/forgot-password" */))
const _97007e74 = () => interopDefault(import('../pages/holiday-manage/index.vue' /* webpackChunkName: "pages/holiday-manage/index" */))
const _71d4fc87 = () => interopDefault(import('../pages/leave-manage/index.vue' /* webpackChunkName: "pages/leave-manage/index" */))
const _3b54dbf0 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _0ca8ae73 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _186c13f9 = () => interopDefault(import('../pages/my-info/index.vue' /* webpackChunkName: "pages/my-info/index" */))
const _291f1a15 = () => interopDefault(import('../pages/my-salary/index.vue' /* webpackChunkName: "pages/my-salary/index" */))
const _75d372e6 = () => interopDefault(import('../pages/receipt-manage/index.vue' /* webpackChunkName: "pages/receipt-manage/index" */))
const _846187dc = () => interopDefault(import('../pages/salary-manage/index.vue' /* webpackChunkName: "pages/salary-manage/index" */))
const _f1da8da2 = () => interopDefault(import('../pages/time-manage/index.vue' /* webpackChunkName: "pages/time-manage/index" */))
const _33db8db5 = () => interopDefault(import('../pages/working-time/index.vue' /* webpackChunkName: "pages/working-time/index" */))
const _a2e3b13c = () => interopDefault(import('../pages/account/add-user.vue' /* webpackChunkName: "pages/account/add-user" */))
const _4d9bfc06 = () => interopDefault(import('../pages/account/terminate.vue' /* webpackChunkName: "pages/account/terminate" */))
const _2eb92dec = () => interopDefault(import('../pages/holiday-manage/view-holiday.vue' /* webpackChunkName: "pages/holiday-manage/view-holiday" */))
const _7ba05a6e = () => interopDefault(import('../pages/leave-manage/unapprove.vue' /* webpackChunkName: "pages/leave-manage/unapprove" */))
const _367aa1b4 = () => interopDefault(import('../pages/leave-manage/user.vue' /* webpackChunkName: "pages/leave-manage/user" */))
const _7fd17738 = () => interopDefault(import('../pages/my-info/salary-info.vue' /* webpackChunkName: "pages/my-info/salary-info" */))
const _8ceac19c = () => interopDefault(import('../pages/my-info/update.vue' /* webpackChunkName: "pages/my-info/update" */))
const _1e40423c = () => interopDefault(import('../pages/salary-manage/browse-request.vue' /* webpackChunkName: "pages/salary-manage/browse-request" */))
const _5996d436 = () => interopDefault(import('../pages/salary-manage/salary-processing.vue' /* webpackChunkName: "pages/salary-manage/salary-processing" */))
const _5b8e2d2f = () => interopDefault(import('../pages/salary-manage/transfer-all.vue' /* webpackChunkName: "pages/salary-manage/transfer-all" */))
const _137d9be2 = () => interopDefault(import('../pages/account/_id/index.vue' /* webpackChunkName: "pages/account/_id/index" */))
const _7583081c = () => interopDefault(import('../pages/leave-manage/_id/index.vue' /* webpackChunkName: "pages/leave-manage/_id/index" */))
const _1e4caad1 = () => interopDefault(import('../pages/receipt-manage/_id/index.vue' /* webpackChunkName: "pages/receipt-manage/_id/index" */))
const _567da49a = () => interopDefault(import('../pages/time-manage/_id/index.vue' /* webpackChunkName: "pages/time-manage/_id/index" */))
const _629c964a = () => interopDefault(import('../pages/account/_id/leave-application.vue' /* webpackChunkName: "pages/account/_id/leave-application" */))
const _dac57770 = () => interopDefault(import('../pages/account/_id/receipt.vue' /* webpackChunkName: "pages/account/_id/receipt" */))
const _146e156d = () => interopDefault(import('../pages/account/_id/salary-cancel.vue' /* webpackChunkName: "pages/account/_id/salary-cancel" */))
const _22c5519e = () => interopDefault(import('../pages/account/_id/salary-history.vue' /* webpackChunkName: "pages/account/_id/salary-history" */))
const _5c422600 = () => interopDefault(import('../pages/account/_id/salary-receive.vue' /* webpackChunkName: "pages/account/_id/salary-receive" */))
const _d49a1eea = () => interopDefault(import('../pages/account/_id/transfer.vue' /* webpackChunkName: "pages/account/_id/transfer" */))
const _20aa1469 = () => interopDefault(import('../pages/account/_id/update.vue' /* webpackChunkName: "pages/account/_id/update" */))
const _7030d49e = () => interopDefault(import('../pages/salary-manage/_id/salary-update-history.vue' /* webpackChunkName: "pages/salary-manage/_id/salary-update-history" */))
const _2c3cd6e0 = () => interopDefault(import('../pages/salary-manage/_id/transfer.vue' /* webpackChunkName: "pages/salary-manage/_id/transfer" */))
const _35cbf5ae = () => interopDefault(import('../pages/salary-manage/_id/update.vue' /* webpackChunkName: "pages/salary-manage/_id/update" */))
const _71ad38d9 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _4dfcd712,
    name: "account"
  }, {
    path: "/change-password",
    component: _34244e5f,
    name: "change-password"
  }, {
    path: "/forgot-password",
    component: _384571cc,
    name: "forgot-password"
  }, {
    path: "/holiday-manage",
    component: _97007e74,
    name: "holiday-manage"
  }, {
    path: "/leave-manage",
    component: _71d4fc87,
    name: "leave-manage"
  }, {
    path: "/login",
    component: _3b54dbf0,
    children: [{
      path: "",
      component: _0ca8ae73,
      name: "login"
    }]
  }, {
    path: "/my-info",
    component: _186c13f9,
    name: "my-info"
  }, {
    path: "/my-salary",
    component: _291f1a15,
    name: "my-salary"
  }, {
    path: "/receipt-manage",
    component: _75d372e6,
    name: "receipt-manage"
  }, {
    path: "/salary-manage",
    component: _846187dc,
    name: "salary-manage"
  }, {
    path: "/time-manage",
    component: _f1da8da2,
    name: "time-manage"
  }, {
    path: "/working-time",
    component: _33db8db5,
    name: "working-time"
  }, {
    path: "/account/add-user",
    component: _a2e3b13c,
    name: "account-add-user"
  }, {
    path: "/account/terminate",
    component: _4d9bfc06,
    name: "account-terminate"
  }, {
    path: "/holiday-manage/view-holiday",
    component: _2eb92dec,
    name: "holiday-manage-view-holiday"
  }, {
    path: "/leave-manage/unapprove",
    component: _7ba05a6e,
    name: "leave-manage-unapprove"
  }, {
    path: "/leave-manage/user",
    component: _367aa1b4,
    name: "leave-manage-user"
  }, {
    path: "/my-info/salary-info",
    component: _7fd17738,
    name: "my-info-salary-info"
  }, {
    path: "/my-info/update",
    component: _8ceac19c,
    name: "my-info-update"
  }, {
    path: "/salary-manage/browse-request",
    component: _1e40423c,
    name: "salary-manage-browse-request"
  }, {
    path: "/salary-manage/salary-processing",
    component: _5996d436,
    name: "salary-manage-salary-processing"
  }, {
    path: "/salary-manage/transfer-all",
    component: _5b8e2d2f,
    name: "salary-manage-transfer-all"
  }, {
    path: "/account/:id",
    component: _137d9be2,
    name: "account-id"
  }, {
    path: "/leave-manage/:id",
    component: _7583081c,
    name: "leave-manage-id"
  }, {
    path: "/receipt-manage/:id",
    component: _1e4caad1,
    name: "receipt-manage-id"
  }, {
    path: "/time-manage/:id",
    component: _567da49a,
    name: "time-manage-id"
  }, {
    path: "/account/:id/leave-application",
    component: _629c964a,
    name: "account-id-leave-application"
  }, {
    path: "/account/:id/receipt",
    component: _dac57770,
    name: "account-id-receipt"
  }, {
    path: "/account/:id/salary-cancel",
    component: _146e156d,
    name: "account-id-salary-cancel"
  }, {
    path: "/account/:id/salary-history",
    component: _22c5519e,
    name: "account-id-salary-history"
  }, {
    path: "/account/:id/salary-receive",
    component: _5c422600,
    name: "account-id-salary-receive"
  }, {
    path: "/account/:id/transfer",
    component: _d49a1eea,
    name: "account-id-transfer"
  }, {
    path: "/account/:id/update",
    component: _20aa1469,
    name: "account-id-update"
  }, {
    path: "/salary-manage/:id?/salary-update-history",
    component: _7030d49e,
    name: "salary-manage-id-salary-update-history"
  }, {
    path: "/salary-manage/:id?/transfer",
    component: _2c3cd6e0,
    name: "salary-manage-id-transfer"
  }, {
    path: "/salary-manage/:id?/update",
    component: _35cbf5ae,
    name: "salary-manage-id-update"
  }, {
    path: "/",
    component: _71ad38d9,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
