import Vue from 'vue';
import { ValidationProvider, ValidationObserver, setInteractionMode  } from "vee-validate";
import * as VeeValidate from 'vee-validate';
import { extend, localize } from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import vi from 'vee-validate/dist/locale/vi.json';
import en from 'vee-validate/dist/locale/en.json';
import ja from 'vee-validate/dist/locale/ja.json';

Vue.use(VeeValidate, {
  inject: true
});

// localize('en', en);
// localize('ja', ja);
// localize('vi', vi);
localize({
  en,
  vi,
  ja
});
localize({
  vi: {
    messages: {
      phone: 'Số điện thoại không hợp lệ',
      password_not_trim: 'Mật khẩu không được bắt đầu hoặc kết thúc bằng khoảng trắng',
      new_pass_differ_current:"Mật khẩu mới phải khác với mật khẩu hiện tại",
    }
  },
  en: {
    messages: {
      phone: 'Invalid phone number',
      password_not_trim: 'Password must not start or end with a space',
      new_pass_differ_current:"A new password must be different from the current password",
    }
  },
  ja: {
    messages: {
      phone: '無効な電話番号',
      password_not_trim: 'パスワードの先頭または末尾にスペースを使用することはできません',
      new_pass_differ_current:"新しいパスワードは現在のパスワードとは異なる必要があります",
    }
  }
});
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

//set custom interaction default mode
setInteractionMode('custom', context => {
  return { on: ['input'] };
});

// Install rules
Object.keys(rules).forEach(rule => {
  extend(rule, {
    ...rules[rule], // copies rule configuration
    // message: localize[rule] // assign message
  });
});

extend('phone', {
  validate(value) {
    return formatPhoneNumber(value);
  },
  // message: (fieldName) => {
  //   return `Số điện thoại không hợp lệ`;
  // }
});

function formatPhoneNumber(phoneNumberString) {
  //regex vn phone number
  // return /(03|05|07|08|09|01[2|6|8|9])+([0-9]{8})\b/.test(phoneNumberString);
  return /^[0-9]*$/.test(phoneNumberString)
}

extend('password_not_trim', {
  validate(value) {
    return !value.startsWith(' ') && !value.endsWith(' ');
  },
});

extend('new_pass_differ_current', {
  params: ['target'],
  validate(value, { target }) {
    console.log(value, target)
    return value !== target;
  },
});
