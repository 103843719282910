var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "20",
        height: "20",
        viewBox: "0 0 20 20",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M18.7508 9.99888H17.5008C17.4026 9.99888 17.3223 10.0792 17.3223 10.1775V17.3203H2.67941V2.67746H9.82227C9.92048 2.67746 10.0008 2.5971 10.0008 2.49888V1.24888C10.0008 1.15067 9.92048 1.07031 9.82227 1.07031H1.78655C1.39146 1.07031 1.07227 1.38951 1.07227 1.7846V18.2132C1.07227 18.6083 1.39146 18.9275 1.78655 18.9275H18.2151C18.6102 18.9275 18.9294 18.6083 18.9294 18.2132V10.1775C18.9294 10.0792 18.8491 9.99888 18.7508 9.99888Z",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M6.51631 10.51L6.4739 13.1641C6.47167 13.3627 6.63239 13.5257 6.83105 13.5257H6.83998L9.4739 13.4609C9.51855 13.4587 9.56319 13.4408 9.59444 13.4096L18.8779 4.14621C18.9471 4.07701 18.9471 3.96317 18.8779 3.89397L16.1034 1.12165C16.0677 1.08594 16.023 1.07031 15.9761 1.07031C15.9293 1.07031 15.8846 1.08817 15.8489 1.12165L6.56765 10.385C6.53541 10.4188 6.51708 10.4634 6.51631 10.51ZM7.93373 11.0368L15.9761 3.01228L16.9851 4.01897L8.93819 12.048L7.9181 12.0725L7.93373 11.0368Z",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }