<template>
	<div class="overlay">
		<div class="loading">
		</div>
	</div>
</template>
<script>
export default {
}
</script>
<style scoped>
.overlay {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #00000080;
	z-index: 9999;
	display: flex;
	align-items: center;
	justify-content: center;
}

.loading {
	border: 10px solid #f3f3f3;
	border-radius: 50%;
	border-top: 10px solid #3498db;
	width: 100px;
	height: 100px;
	-webkit-animation: spin 2s linear infinite;
	/* Safari */
	animation: spin 2s linear infinite;
	margin: 12px;
}

@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}
</style>