var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "header",
    {
      staticClass: "position-fixed z-index-10 w-100",
      class: { "bg-white": _vm.$auth.loggedIn },
    },
    [
      _c(
        "div",
        { staticClass: "d-flex justify-content-between navbar-wrapper" },
        [
          _c(
            "nuxt-link",
            {
              staticClass: "logo",
              class: {
                "logo-login": _vm.$route.path == _vm.localePath("/login"),
              },
              attrs: { to: _vm.getUrlHomePage },
              nativeOn: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.onScrollTop()
                },
              },
            },
            [
              _c("img", {
                staticStyle: {},
                attrs: {
                  src: require("assets/images/logo-vj@2x.png"),
                  alt: "",
                  srcset: "",
                },
              }),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "d-flex justify-content-between align-items-center",
            },
            [
              _c(
                "div",
                { staticClass: "language mr-3" },
                [
                  _c("v-select", {
                    attrs: {
                      options: _vm.listLanguages,
                      isRight: true,
                      itemsWidth: "240px",
                    },
                    on: { "change-data": _vm.onChangeLanguage },
                    model: {
                      value: _vm.language,
                      callback: function ($$v) {
                        _vm.language = $$v
                      },
                      expression: "language",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.$route.path.includes("/login"),
                      expression: "!$route.path.includes('/login')",
                    },
                    {
                      name: "click-outside",
                      rawName: "v-click-outside",
                      value: () => {
                        _vm.isOpen = false
                      },
                      expression: "() => { isOpen = false }",
                    },
                  ],
                  staticClass: "icon-menu",
                  on: {
                    click: function ($event) {
                      _vm.isOpen = !_vm.isOpen
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "24",
                        height: "24",
                        viewBox: "0 0 24 24",
                        fill: "none",
                        xmlns: "http://www.w3.org/2000/svg",
                      },
                    },
                    [
                      _c("rect", {
                        attrs: {
                          x: "19.9609",
                          y: "6.44385",
                          width: "2.89474",
                          height: "16",
                          transform: "rotate(89.5967 19.9609 6.44385)",
                          fill: "#183052",
                        },
                      }),
                      _vm._v(" "),
                      _c("rect", {
                        attrs: {
                          x: "19.9937",
                          y: "11.0752",
                          width: "2.31579",
                          height: "16",
                          transform: "rotate(89.5967 19.9937 11.0752)",
                          fill: "#183052",
                        },
                      }),
                      _vm._v(" "),
                      _c("rect", {
                        attrs: {
                          x: "20.0264",
                          y: "15.7068",
                          width: "1.73684",
                          height: "16",
                          transform: "rotate(89.5967 20.0264 15.7068)",
                          fill: "#183052",
                        },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.isOpen
                    ? _c("div", { staticClass: "dropdown_type" }, [
                        _c("div", { staticClass: "mt-1" }),
                        _vm._v(" "),
                        _vm.$auth.loggedIn
                          ? _c(
                              "div",
                              [
                                _vm.$auth.user.role == "ROLE_ADMIN" ||
                                _vm.$auth.user.role == "ROLE_MOD"
                                  ? _c(
                                      "nuxt-link",
                                      {
                                        staticClass:
                                          "d-flex dropdown_type__item",
                                        attrs: {
                                          to: _vm.localePath(
                                            "/account/terminate"
                                          ),
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("auth.terminate")))]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.$auth.user.role !== "ROLE_ADMIN"
                                  ? _c(
                                      "nuxt-link",
                                      {
                                        staticClass:
                                          "d-flex dropdown_type__item",
                                        attrs: {
                                          to: _vm.localePath(
                                            "/change-password"
                                          ),
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("auth.change_password"))
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "dropdown_type__item",
                                    on: {
                                      click: function ($event) {
                                        return _vm.confirmLogout()
                                      },
                                    },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.$t("auth.log_out"))),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _c(
                              "div",
                              [
                                _c(
                                  "nuxt-link",
                                  {
                                    staticClass: "d-flex dropdown_type__item",
                                    attrs: { to: _vm.localePath("/login") },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("auth.log_in")))]
                                ),
                              ],
                              1
                            ),
                        _vm._v(" "),
                        _c("div", { staticClass: "mb-1" }),
                      ])
                    : _vm._e(),
                ]
              ),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }