var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(_vm.iconName, {
    tag: "component",
    attrs: { fill: _vm.fill, width: _vm.width, height: _vm.height },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }