<template>
  <div class="container-fluid w-100 d-flex flex-column flex-lg-row justify-content-center align-items-enter">
    <div class="content-wrapper d-flex flex-column justify-content-center text-left">
      <h2 class="title">{{ this.$t('error.page_not_found') }} !</h2>
      <p class="desc mt-4">{{ this.$t('error.page_not_found_desc') }}</p>
      <button class="btn-custom mt-4" @click="$router.push('/')">
        {{ this.$t('error.go_back_home') }}
      </button>
    </div>
    <div class="d-flex align-items-center h-100">
      <img src="~/assets/images/404-error.png" alt="404"/>
    </div>
  </div>
</template>
<script>
export default {
  layout: 'empty',
  head() {
    return {
      title: this.$t('error.error')
    }
  }
}
</script>
<style lang="scss" scoped>
.container-fluid {
  height: 100vh;
  padding: 4rem;
  img {
    max-width: 500px;
    max-height: 500px;
  }
}
.content-wrapper {
  width: 40%;
  @media screen and (max-width: 767.98px) {
    width: 100%;
  }
}
.btn-custom {
  height: 48px;
}
</style>