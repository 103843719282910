import Vue from 'vue';
import VueFlatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import { Japanese } from 'flatpickr/dist/l10n/ja.js'
import { Vietnamese } from 'flatpickr/dist/l10n/vn.js'
import { english } from 'flatpickr/dist/l10n/default'

Vue.use(VueFlatPickr);

export default ({app}) => {
  switch (app.i18n._vm.locale) {
    case 'ja':
      flatpickr.localize(Japanese)
      break
    case 'vi':
      flatpickr.localize(Vietnamese)
      break
    default:
      flatpickr.localize(english)
      break
  }
}