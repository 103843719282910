export default function ({ app, $axios, redirect }) {
  // $axios.onRequest(config => {
  //   console.log('Making request to ' + config.url)
  // })
  let loadOnce = false;
  $axios.onResponseError(error => {
    const code = error.response?.data?.error?.error
    if(app.$auth.loggedIn) {
      if (code === 'ERROR.AD') {
        if (loadOnce) {
          return;
        }
        loadOnce = true;
        $nuxt.$emit('account-deactivate', code);
      } else if (
        [
          "ERR.TOK0101",
          "ERR.TOK0103",
          "ERR.TOK0201",
          "ERR.TOK0202",
          "ERR.TOK0203",
        ].includes(error?.code ?? "")
      ) {
        app.$auth.reset();
        redirect("/");
      }
      return Promise.reject(error);
    }
  })
}