<template>
  <div v-click-outside="() => { isOpen = false }" @click.prevent>
    <div class="box-select position-relative">
      <div class="d-flex align-items-center justify-content-between" 
        @click="openDropdown">
        <div class="box-select--name flex-1">                                                                                                                                  
          <span v-if="innerValue">{{ innerValue.name }}</span>
          <span v-else>{{ $t('common.select') }}</span>
        </div>
        <div class="icon-style d-inline-flex">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.5 8.50743L12 17L2.5 8.50743L4.18625 7L12 13.9851L19.8138 7L21.5 8.50743Z" fill="#777070"/>
          </svg>
        </div>
      </div>
      <div class="dropdown_type" :class="{'select-month': $attrs.isSelectMonth}" v-if="isOpen">
        <div class="mt-1"></div>
        <div class="dropdown_type__item" :class="{'active': item.id == value}"
          v-for="item in options" :key="item.id" @click.prevent="select(item)">
          <span>{{item.name}}</span>
        </div>
        <div class="mb-1"></div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'VSelect',
  components: {
  },
  props: {
    options: {
      type: Array,
      default: []
    },
    value: {
      default: ''
    },
    itemsWidth: {
      type: String,
      default: '100%'
    },
    isLeft: {
      type: Boolean,
      default: false
    },
    isRight: {
      type: Boolean,
      default: false
    },
    textAlign: {
      type: String,
      default: 'left'
    },
    backgroundColor: {
      type: String,
      default: '#FFF'
    }
  },
  data() {
    return {
      innerValue: '',
      isOpen: false,
      isLeftCss: this.isLeft ? '0' : 'auto',
      isRightCss: this.isRight ? '0' : 'auto'
    }
  },
  watch: {
    // innerValue (val) {
    //   console.log(val);
    //   if (val) {
    //     this.$emit('change-data', val.id);
    //   }
    // },
    value (newVal) {
      if (newVal) {
        this.innerValue = this.options.find(option => option.id === newVal);
      }
    }
  },
  created() {
    if(this.value || (typeof this.value == 'number')) {
      this.innerValue = this.options.find( option => option.id === this.value);
    }
  },
  methods: {
    openDropdown() {
      this.isOpen = !this.isOpen;
    },
    select(item) {
      this.innerValue = item;
      this.$emit('input', this.innerValue.id);
      this.$emit('change-data', this.innerValue.id);
      this.isOpen = false;
    }
  }
}
</script>

<style scoped lang="scss">
.box-select {
  // width: 100%;
  box-sizing: border-box;
  padding: 3px 8px;
  background: #FFF;
  border: 0.5px solid #C0BABA;
  border-radius: 4px;
  >div:first-child {
    cursor: pointer;
  }

  &--name {
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #51555C;
  }
  .icon-style {
    margin-left: 12px;
    &:hover {
      cursor: pointer;
    }
  }
  .dropdown_type {
    position: absolute;
    border-top: 0;
    box-sizing: border-box;
    top: calc(100% + 8px);
    left: v-bind(isLeftCss);
    right: v-bind(isRightCss);
    width: v-bind(itemsWidth);
    filter: none;
    z-index: 999;
    text-align: v-bind(textAlign);
    max-height: 450px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 0;
    }
    &__item {
      background: v-bind(backgroundColor);
      padding: 5px 12px;
      font-family: 'Roboto';
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.85);
      &:hover {
        cursor: pointer;
        background: #CDD6DF;
      }
      &.active {
        background: #4DA4D6;
        font-weight: 500;
        pointer-events: none;
        cursor: default;
        span {
          pointer-events: none;
          cursor: default;
        }
      }
    }
    &.select-month {
      display: flex;
      flex-direction: column;
      gap: 12px;
      border: 0.5px solid rgba(0, 0, 0, 0.15);
      background: #FAFAFA;
      box-shadow: 0px 4px 4px 0px rgba(156, 156, 156, 0.10)
    }
  }
}
</style>