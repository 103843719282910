const middleware = {}

middleware['admin-guard'] = require('../middleware/admin-guard.js')
middleware['admin-guard'] = middleware['admin-guard'].default || middleware['admin-guard']

middleware['admin-mod-guard'] = require('../middleware/admin-mod-guard.js')
middleware['admin-mod-guard'] = middleware['admin-mod-guard'].default || middleware['admin-mod-guard']

middleware['auth'] = require('../middleware/auth.js')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['protect-guard'] = require('../middleware/protect-guard.js')
middleware['protect-guard'] = middleware['protect-guard'].default || middleware['protect-guard']

middleware['user-guard'] = require('../middleware/user-guard.js')
middleware['user-guard'] = middleware['user-guard'].default || middleware['user-guard']

export default middleware
