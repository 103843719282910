var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "container-fluid w-100 d-flex flex-column flex-lg-row justify-content-center align-items-enter",
    },
    [
      _c(
        "div",
        {
          staticClass:
            "content-wrapper d-flex flex-column justify-content-center text-left",
        },
        [
          _c("h2", { staticClass: "title" }, [
            _vm._v(_vm._s(this.$t("error.page_not_found")) + " !"),
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "desc mt-4" }, [
            _vm._v(_vm._s(this.$t("error.page_not_found_desc"))),
          ]),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn-custom mt-4",
              on: {
                click: function ($event) {
                  return _vm.$router.push("/")
                },
              },
            },
            [
              _vm._v(
                "\n      " + _vm._s(this.$t("error.go_back_home")) + "\n    "
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _vm._m(0),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "d-flex align-items-center h-100" }, [
      _c("img", {
        attrs: { src: require("assets/images/404-error.png"), alt: "404" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }