import Vuex from 'vuex';
import loading from "./loading";

const createStore = () => {
  return new Vuex.Store({
    modules: {
      loading: loading
    },
  });
};
export default createStore