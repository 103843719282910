<template>
  <div id="app-container">
    <top-nav-bar/>
    <div class="d-flex h-100 w-100 body">
      <left-bar v-if="$auth.loggedIn">
      </left-bar>
      <div class="flex-grow-1 right-content" :class="{'login-page': $route.path.includes('/login')}">
        <nuxt class="nuxt-content" :class="{'content': $auth.loggedIn}"></nuxt>
      </div>
    </div>
    <LoadingLayout v-if="isLoading"/>
    <b-modal id="modal-no-internet"
      ok-only
      okVariant="danger"
      :title="$t('error.error')"
      headerClass="px-4 pt-3 pb-0 border-bottom-0"
      bodyClass="px-4 py-2"
      footerClass="px-4 pb-3 pt-0 border-top-0"
      button-size="sm"
      @ok="() => { isOffline = false }"
      v-model="isOffline"
      :ok-title="$t('common.ok')"
      :cancel-title="$t('account_info.cancel')"
      >
      <p class="my-4">{{ $t('error.no_internet') }}</p>
    </b-modal>
  </div>
</template>

<script>
import LeftBar from '~/components/LeftBar.vue';
import TopNavBar from '~/components/TopNavBar.vue';
import LoadingLayout from '~/components/LoadingLayout.vue'
import { mapGetters  } from 'vuex';

export default {
  components: {
    TopNavBar,
    LeftBar,
    LoadingLayout
  },
  data() {
    return {
      isOffline: false
    }
  },
  computed: {
    ...mapGetters({ 
      isLoading: 'loading/getIsLoading',
    }),
  },
  watch: {
    '$nuxt.isOffline': {
      handler: function(newVal) {
        if (newVal) {
          this.isOffline = true;
        } else {
          window.location.reload();
        }
      }
    }
  },
  mounted() {
    window.addEventListener("storage", async (e) => {
      try {
        if(e.key === 'auth._token.local' && !this.$isIE) {
          if(!e.oldValue) return;
          if(e.newValue == 'false') {
            this.$auth.logout().then(() => {
              this.$router.push(this.localePath('/login'));
            });
          } else window.location.reload();
          return;
        }
      } catch (error) {
      }
    });
    this.$nuxt.$on('account-deactivate', (err) => {
      this.$bvModal.msgBoxOk(this.$t(`error.${err}`).toString(), {
        title: this.$t('error.error').toString(),
        size: 'md',
        buttonSize: 'sm',
        okVariant: 'danger',
        headerClass: 'p-3 border-bottom-0',
        footerClass: 'p-3 border-top-0',
        okTitle: this.$t('common.ok'),
        cancelTitle: this.$t('account_info.cancel'),
        hideHeaderClose: true,
      }).then(() => {
        this.$auth.logout().then(() => {
          this.$router.push(this.localePath('/login'));
          // this.$nuxt.$off('account-deactivate');
        });
      }).catch(err => {
      })
    });
  },
  methods: {
  }
}
</script>

<style scoped lang="scss">
.body {
  // padding-top: 136px;
  padding-top: 76px;
}
.loading-animation {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(205, 214, 223, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.right-content {
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 76px);
  background-color: #F8F8F8;
  overflow-x: auto;
  @media screen and (max-width: 767.98px) {
    min-width: 1000px;
  }
  &.login-page {
    background-color: transparent;
    overflow-x: initial;
  }
  .nuxt-content {
    min-width: 1000px;
  }
  // padding: 20px;
  // &.content {
  //   background: rgba(236, 236, 236, 0.5);;
  // }
}
</style>